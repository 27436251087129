import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  if (user) {
    return children;
  } else {
    // Store the intended destination
    localStorage.setItem("intendedPath", location.pathname);
    return <Navigate to="/signin" />;
  }
};

export default PrivateRoute;
