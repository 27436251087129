import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ticketServices from "./ticketService";

const initialState = {
  ticket: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//initiateTicket

export const initiateTicket = createAsyncThunk(
  "ticket/initiateTicket",
  async (ticketData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await ticketServices.initiateTicket(ticketData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const formSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.ticket = null;
      state.message = "";
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initiateTicket.fulfilled, (state, { payload }) => {
        state.ticket = payload.ticket;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(initiateTicket.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      });
  },
});

export const { clearMessage } = formSlice.actions;

export default formSlice.reducer;
