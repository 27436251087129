import axios from "axios";

const API_URL = "/api/users/";

//register new user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

//Login with Google
const loginWithGoogle = async (userData) => {
  const response = await axios.post(API_URL + "googlelogin", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

//Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Get user
const getUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      API_URL + "getuser",
      { id: userId },
      config
    );

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

const updatePassword = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(
      API_URL + "updatepassword",
      userData,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

//forgot password
const forgotPassword = async (userData) => {
  const response = await axios.post(API_URL + "forgotpassword", userData);

  return response.data;
};

//reset password
const resetPassword = async (userData, token) => {
  const response = await axios.get(API_URL + "resetpassword/" + token);

  return response.data;
};

// Update user data
const updateUserData = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(
      API_URL + "updateuserdata",
      userData,
      config
    );

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Add child from admin
const addChildFromAdmin = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      API_URL + "addchildfromadmin",
      userData,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Update user data from admin
const updateUserDataFromAdmin = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(
      API_URL + "updateuserdatafromadmin",
      userData,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Update children data
const updateChildrenData = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(
      API_URL + "updatechildren",
      userData,
      config
    );

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// remove child
const removeChild = async (userData, token) => {
  const { email, childId } = userData;

  try {
    const response = await axios.delete("/api/users/removechild", {
      data: {
        email,
        childId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// register User FromAdmin
const registerUserFromAdmin = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      API_URL + "registeruserfromadmin",
      userData,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

//Logout user
const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  logout,
  login,
  getUser,
  loginWithGoogle,
  updatePassword,
  updateUserData,
  forgotPassword,
  resetPassword,
  removeChild,
  updateChildrenData,
  registerUserFromAdmin,
  updateUserDataFromAdmin,
  addChildFromAdmin,
};

export default authService;
