import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaBirthdayCake,
  FaCamera,
  FaCandyCane,
} from "react-icons/fa";
import { IoFootstepsSharp } from "react-icons/io5";
import { LuPartyPopper } from "react-icons/lu";
import { Link } from "react-router-dom";
import logo from "../assets/images/logoparkane.png";
import { useSelector } from "react-redux";
import WhatsAppButton from "./WhatsAppButton";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <footer className="bg-dark text-white mt-5 pt-3">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3 mb-3">
            <h5>Servicios</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  to="/picatutorta"
                  className="text-decoration-none text-white"
                >
                  <FaBirthdayCake className="color-titulo" /> Pica Tu Torta
                </Link>
              </li>
              <li>
                <Link
                  to="/fiestaprivada"
                  className="text-decoration-none text-white"
                >
                  <LuPartyPopper className="color-titulo" /> Fiesta Privada
                </Link>
              </li>
              <li>
                <Link to="/eventos" className="text-decoration-none text-white">
                  <FaCamera className="color-titulo" /> Eventos
                </Link>
              </li>
              <li>
                <Link
                  to={
                    user?.role === "admin" ? "/admin-dashboard" : "/dashboard"
                  }
                  className="text-decoration-none text-white"
                >
                  <FaCandyCane className="color-titulo" /> Mi cuenta
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 mb-3">
            <h5>Contacto</h5>
            <p>
              <span className="color-titulo">Dirección:</span> Av. Paez, edif.
              Rovi planta baja, Local: s/n. Sector Principal Acarigua,
              Portuguesa
              <br />
              <span className="color-titulo">Teléfono:</span> 0414-5020050
              <br />
              <span className="color-titulo">Email:</span>{" "}
              happines.park@gmail.com
            </p>
          </div>
          <div className="col-md-3 mb-3">
            <h5>Síguenos</h5>
            <div className="d-flex">
              <span className="iconContainerStyle">
                <a
                  href="https://www.instagram.com/happiness.park_/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className="iconStyle" />
                </a>
              </span>
              <span className="iconContainerStyle">
                <a
                  href="https://www.facebook.com/profile.php?id=100095143322214"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className="iconStyle" />
                </a>
              </span>
            </div>
            <Link
              to="/como-llegar"
              className="btn btn-outline-light fw-bold mb-5 mt-4 px-2 py-2"
            >
              <div>
                <div className="d-flex align-items-center">
                  <IoFootstepsSharp className="me-2" /> Cómo llegar
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3 mb-3">
            <h5>Horarios</h5>
            <p>
              <span className="color-titulo">
                Viernes, sábados y Domingos:
                <br />
              </span>
              2:30 pm - 10:00 pm
              <br />
              "Abiertos al público general"
            </p>
            <p>
              <span className="color-titulo">Evento privado:</span>
              <br />
              2:30 pm - 9:00 pm
              <br />
              "Cualquier día de la semana"
            </p>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-center">
          <div className="mb-3">
            <Link to="/">
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <p>© 2023 Happiness Park C.A. Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
      <WhatsAppButton isMobile={false} />
    </footer>
  );
};

export default React.memo(Footer);
