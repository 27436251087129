import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import formServices from "./formService";

//Get user from local storage
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  formData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  tickets: [],
  ticket: null,
};

// send form from frontpage

export const sendFormMain = createAsyncThunk(
  "form/sendFormMain",
  async (userData, thunkAPI) => {
    try {
      return await formServices.sendFormMain(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//send ticket from ticket page
export const sendTicket = createAsyncThunk(
  "form/sendFormTicket",
  async (ticketData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.sendTicket(ticketData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get tickets from user
export const getTickets = createAsyncThunk(
  "form/getTickets",
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.getTickets(userId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get ticket by id from admin
export const getTicketById = createAsyncThunk(
  "form/getTicketById",
  async (ticketId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.getTicketById(ticketId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get ticket by id from admin
export const getTicketByIdPublic = createAsyncThunk(
  "form/getTicketByIdPublic",
  async (ticketId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.getTicketByIdPublic(ticketId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Save new newsletter subscriber
export const newsletterNew = createAsyncThunk(
  "form/newsletternew",
  async (email, thunkAPI) => {
    try {
      return await formServices.newsletterNew(email);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all tickets in progress from admin
export const ticketsInProgress = createAsyncThunk(
  "form/ticketsInProgress",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.ticketsInProgress(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get all event tickets
export const getEventTickets = createAsyncThunk(
  "form/getEventTickets",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.eventTickets(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Pause ticket
export const pauseTicket = createAsyncThunk(
  "form/pauseTicket",
  async (ticketId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.pauseTicket(ticketId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Resume ticket
export const resumeTicket = createAsyncThunk(
  "form/resumeTicket",
  async (ticketId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.resumeTicket(ticketId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Finish ticket
export const finishTicket = createAsyncThunk(
  "form/finishTicket",
  async ({ totalAmount, ticketId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await formServices.finishTicket(ticketId, totalAmount, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.message = "";
      state.formData = null;
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendFormMain.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendFormMain.fulfilled, (state, { payload }) => {
        state.user = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
        state.formData = {
          name: payload.name,
          email: payload.email,
          interested: payload.interested,
          phoneNumber: payload.phoneNumber,
        };
      })
      .addCase(sendFormMain.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(sendTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendTicket.fulfilled, (state, { payload }) => {
        state.user = null;
        state.formData = {
          children: payload.children,
          type: payload.type,
          date: payload.date,
          paymentMethod: payload.paymentMethod,
          totalPrice: payload.totalPrice,
        };
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
        state.ticket = payload.ticket;
      })
      .addCase(sendTicket.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(getTickets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTickets.fulfilled, (state, { payload }) => {
        state.user = null;
        state.tickets = payload.tickets;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(getTickets.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(newsletterNew.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(newsletterNew.fulfilled, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(newsletterNew.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(getTicketById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTicketById.fulfilled, (state, { payload }) => {
        state.user = null;
        state.ticket = payload.ticket;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(getTicketById.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(ticketsInProgress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ticketsInProgress.fulfilled, (state, { payload }) => {
        state.user = null;
        state.tickets = payload.tickets;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(ticketsInProgress.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(getEventTickets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEventTickets.fulfilled, (state, { payload }) => {
        state.user = null;
        state.tickets = payload.tickets;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(getEventTickets.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(pauseTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(pauseTicket.fulfilled, (state, { payload }) => {
        state.user = null;
        state.ticket = payload.ticket;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(pauseTicket.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(resumeTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resumeTicket.fulfilled, (state, { payload }) => {
        state.user = null;
        state.ticket = payload.ticket;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(resumeTicket.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      })
      .addCase(finishTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finishTicket.fulfilled, (state, { payload }) => {
        state.user = null;
        state.ticket = payload.ticket;
        state.formData = null;
        state.isLoading = false;
        state.isSuccess = true;
        state.message = payload.message;
      })
      .addCase(finishTicket.rejected, (state, { payload }) => {
        state.user = null;
        state.formData = null;
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
      });
  },
});

export const { clearMessage } = formSlice.actions;

export default formSlice.reducer;
