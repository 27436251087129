import axios from "axios";

const API_URL = "/api/forms/";

//send form from frontpage
const sendFormMain = async (userData) => {
  try {
    const response = await axios.post(API_URL + "sendformmain", userData);

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

//send ticket from ticket page
const sendTicket = async (ticketData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      API_URL + "sendticket",
      ticketData,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Get tickets from user
const getTickets = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${API_URL}getAllTickets/${userId}`,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// getTicketById from admin
const getTicketById = async (ticketId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${API_URL}scanticket/${ticketId}`,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Get ticket by id from public
const getTicketByIdPublic = async (ticketId) => {
  try {
    const response = await axios.get(`${API_URL}getticket/${ticketId}`);

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Save newsletter subscriber
const newsletterNew = async (userData) => {
  try {
    const response = await axios.post(API_URL + "newsletternew", userData);

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// get all tickets in progress from admin
const ticketsInProgress = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${API_URL}ticketsInProgress`, config);

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// get all event tickets from admin
const eventTickets = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(`${API_URL}eventTickets`, config);

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Pause ticket
const pauseTicket = async (ticketId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(
      `${API_URL}pauseTicket/${ticketId}`,
      {},
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// resume ticket
const resumeTicket = async (ticketId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(
      `${API_URL}resumeTicket/${ticketId}`,
      {},
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

// Finish ticket
const finishTicket = async (ticketId, totalAmount, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    totalAmount,
  };

  try {
    const response = await axios.put(
      `${API_URL}finishTicket/${ticketId}`,
      body,
      config
    );

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

const formServices = {
  sendFormMain,
  sendTicket,
  getTickets,
  newsletterNew,
  getTicketById,
  getTicketByIdPublic,
  ticketsInProgress,
  eventTickets,
  pauseTicket,
  resumeTicket,
  finishTicket,
};

export default formServices;
