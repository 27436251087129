import axios from "axios";
const API_URL = "/api/eventos";
const OPENAI_API_URL = "/api/openai";

//Get all events
const getAllEvents = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

// Get a single event by ID
const getEventById = async (eventId) => {
  const response = await axios.get(`${API_URL}/${eventId}`);
  return response.data;
};

// Create a new event
const createEvent = async (eventData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, eventData, config);
  return response.data;
};

// Create Event Flyer
const createEventFlyer = async (eventData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    OPENAI_API_URL + "/generate-flyer",
    eventData,
    config
  );
  return response.data;
};

// Update an event by ID
const updateEvent = async (eventId, eventData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${API_URL}/${eventId}`, eventData, config);
  return response.data;
};

// Delete an event by ID
const deleteEvent = async (eventId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URL}/${eventId}`, config);
  return response.data;
};

const eventService = {
  getAllEvents,
  getEventById,
  createEvent,
  updateEvent,
  deleteEvent,
  createEventFlyer,
};

export default eventService;
