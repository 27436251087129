import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import Spinner from "./components/Spinner";

// Lazy load pages
const HomePage = React.lazy(() => import("./pages/HomePage"));
const SignIn = React.lazy(() => import("./pages/SignIn"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const AdminDashboard = React.lazy(() => import("./pages/AdminDashboard"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const CovidGuidelines = React.lazy(() => import("./pages/CovidGuidelines"));
const FAQs = React.lazy(() => import("./pages/FAQs"));
const Gallery = React.lazy(() => import("./pages/Gallery"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Services = React.lazy(() => import("./pages/Services"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndConditions")
);
const Testimonials = React.lazy(() => import("./pages/Testimonials"));
const PicaTuTorta = React.lazy(() => import("./pages/PicaTuTorta"));
const FiestaPivada = React.lazy(() => import("./pages/FiestaPrivada"));
const Eventos = React.lazy(() => import("./pages/Eventos"));
const Evento = React.lazy(() => import("./pages/Evento"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const SnacksCoffe = React.lazy(() => import("./pages/SnacksCoffe"));
const Educacion = React.lazy(() => import("./pages/Educacion"));
const Entretenimiento = React.lazy(() => import("./pages/Entretenimiento"));
const TicketsMain = React.lazy(() => import("./pages/TicketsMain"));
const TicketGeneral = React.lazy(() => import("./pages/TicketGeneral"));
const ConfirmarTicket = React.lazy(() => import("./pages/ConfirmarTicket"));
const MyTickets = React.lazy(() => import("./pages/MyTickets"));
const RunningTickets = React.lazy(() => import("./pages/RunningTickets"));
const EventTickets = React.lazy(() => import("./pages/EventTickets"));
const TicketEscolar = React.lazy(() => import("./pages/TicketEscolar"));
const MyReservations = React.lazy(() => import("./pages/MyReservations"));
const MyPayments = React.lazy(() => import("./pages/MyPayments"));
const CreateEvent = React.lazy(() => import("./pages/CreateEvent"));
const ViewTicket = React.lazy(() => import("./pages/ViewTicket"));
const ViewTicketAdmin = React.lazy(() => import("./pages/ViewTicketAdmin"));
const BookEvent = React.lazy(() => import("./pages/BookEvent"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const HowToGetToThePark = React.lazy(() => import("./pages/HowToGetToThePark"));
const PackageSingle = React.lazy(() => import("./pages/PackageSingle"));
const CreatePackage = React.lazy(() => import("./pages/CreatePackage"));

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <>
          <NavBar />
          <React.Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contacto" element={<ContactUs />} />
              <Route path="/covidguidelines" element={<CovidGuidelines />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/services" element={<Services />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/picatutorta" element={<PicaTuTorta />} />
              <Route path="/fiestaprivada" element={<FiestaPivada />} />
              <Route path="/snackscoffe" element={<SnacksCoffe />} />
              <Route path="/educacion" element={<Educacion />} />
              <Route path="/entretenimiento" element={<Entretenimiento />} />
              <Route path="/tickets" element={<TicketsMain />} />
              <Route path="/eventos" element={<Eventos />} />
              <Route path="/evento/:id" element={<Evento />} />
              <Route path="/mis-reservas" element={<MyReservations />} />
              <Route path="/mis-pagos" element={<MyPayments />} />
              <Route path="/como-llegar" element={<HowToGetToThePark />} />
              <Route path="/paquete/:id" element={<PackageSingle />} />
              <Route
                path="/ticket-escolar"
                element={
                  <PrivateRoute>
                    <TicketEscolar />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket-general"
                element={
                  <PrivateRoute>
                    <TicketGeneral />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket-general/:id"
                element={
                  <PrivateRoute>
                    <TicketGeneral />
                  </PrivateRoute>
                }
              />
              <Route
                path="/confirmar-ticket"
                element={
                  <PrivateRoute>
                    <ConfirmarTicket />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mistickets"
                element={
                  <PrivateRoute>
                    <MyTickets />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reservar-evento/:id"
                element={
                  <PrivateRoute>
                    <BookEvent />
                  </PrivateRoute>
                }
              />

              <Route
                path="/ticket/:id"
                element={
                  <PrivateRoute>
                    <ViewTicket />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin-dashboard"
                element={
                  <AdminRoute>
                    <AdminDashboard />
                  </AdminRoute>
                }
              />
              <Route
                path="/crear-evento"
                element={
                  <AdminRoute>
                    <CreateEvent />
                  </AdminRoute>
                }
              />
              <Route
                path="/editar-evento/:id"
                element={
                  <AdminRoute>
                    <CreateEvent />
                  </AdminRoute>
                }
              />

              <Route
                path="/paquetes/crear-paquete"
                element={
                  <AdminRoute>
                    <CreatePackage />
                  </AdminRoute>
                }
              />

              <Route
                path="/paquetes/editar-paquete/:id"
                element={
                  <AdminRoute>
                    <CreatePackage />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin-running-tickets"
                element={
                  <AdminRoute>
                    <RunningTickets />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin-event-tickets"
                element={
                  <AdminRoute>
                    <EventTickets />
                  </AdminRoute>
                }
              />
              <Route
                path="/ver-ticket/:id"
                element={
                  <AdminRoute>
                    <ViewTicketAdmin />
                  </AdminRoute>
                }
              />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </React.Suspense>
          <Footer />
        </>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
