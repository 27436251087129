import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSilce";
import { useEffect, useState, useCallback } from "react";
import { FaFacebookF, FaUser, FaInstagram, FaSignOutAlt } from "react-icons/fa";
import logo from "../assets/images/logoparkane.png";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    const isHomePage = location.pathname === "/";

    if (!isHomePage) {
      navbar.classList.add("bg-dark");
      navbar.classList.add("border-bottom", "border-secondary");
    }

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);

        if (isHomePage) {
          navbar.classList.add("bg-dark");
          navbar.classList.add("border-bottom", "border-secondary");
        }
      } else {
        setIsScrolled(false);

        if (isHomePage) {
          navbar.classList.remove("bg-dark");
          navbar.classList.remove("border-bottom", "border-secondary");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  const toggleNav = useCallback(() => {
    setIsNavExpanded((prev) => !prev);
  }, []);

  const closeNav = useCallback(() => {
    setIsNavExpanded(false);
  }, []);

  const onLogout = useCallback(() => {
    if (window.confirm("¿Estás seguro que deseas cerrar sesión?")) {
      dispatch(logout());
      dispatch(reset());
      navigate("/signin");
      closeNav();
    }
  }, [dispatch, navigate, closeNav]);

  return (
    <nav
      className={`navbar navbar-expand-lg sticky-top navbar-dark p-0 m-0 ${
        location.pathname === "/" && !isScrolled
          ? ""
          : "border-bottom border-secondary"
      }`}
    >
      <div className="container">
        <Link className="navbar-brand logo-container" to="/" onClick={closeNav}>
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <div className="d-flex">
          <Link
            to={user?.role === "admin" ? "/admin-dashboard" : "/dashboard"}
            className="usericon-navbar"
          >
            <div className="border rounded py-1 px-2 me-3 text-white">
              <FaUser />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            aria-controls="navbarNavDropdown"
            aria-expanded={isNavExpanded}
            aria-label="Toggle navigation"
            onClick={toggleNav}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className={`collapse navbar-collapse ${
            isNavExpanded ? "show bg-dark pb-5 text-center" : ""
          }`}
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav ms-auto fw-bold fs-6">
            <li className="nav-item">
              <Link className="nav-link" to="/eventos" onClick={closeNav}>
                Eventos
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" onClick={closeNav}>
                Servicios
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/tickets" onClick={closeNav}>
                Tickets
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contacto" onClick={closeNav}>
                Contacto
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link d-flex justify-content-center align-items-center"
                to={user?.role === "admin" ? "/admin-dashboard" : "/dashboard"}
                onClick={closeNav}
              >
                <FaUser className="me-1" /> Mi cuenta
              </Link>
            </li>
            {isNavExpanded && (
              <>
                <li className="nav-item">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link className="nav-link" to="/faqs" onClick={closeNav}>
                      Preguntas Frecuentes
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link
                      className="nav-link"
                      to="/como-llegar"
                      onClick={closeNav}
                    >
                      Cómo llegar
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="d-flex justify-content-center align-items-center">
                    <Link
                      className="nav-link"
                      to="/fiestaprivada"
                      onClick={closeNav}
                    >
                      Paquetes Disponibles
                    </Link>
                  </div>
                </li>
              </>
            )}
            {user ? (
              <li className="nav-item">
                <div className="d-flex justify-content-center align-items-center">
                  <button className="nav-link" onClick={onLogout}>
                    <FaSignOutAlt className="me-1" /> Cerrar sesión
                  </button>
                </div>
              </li>
            ) : (
              <span
                className={`nav-item d-flex justify-content-center align-items-center ${
                  isNavExpanded ? "mt-3" : ""
                }`}
              >
                <span className="iconContainerStyle">
                  <a
                    href="https://www.instagram.com/happiness.park_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="iconStyle" />
                  </a>
                </span>
                <span className="iconContainerStyle">
                  <a
                    href="https://www.facebook.com/profile.php?id=100095143322214"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF className="iconStyle" />
                  </a>
                </span>
              </span>
            )}
            {user && isNavExpanded && (
              <div className="nav-item d-flex justify-content-center align-items-center mt-3">
                <span className="iconContainerStyle">
                  <a
                    href="https://www.instagram.com/happiness.park_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="iconStyle" />
                  </a>
                </span>
                <span className="iconContainerStyle">
                  <a
                    href="https://www.facebook.com/profile.php?id=100095143322214"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF className="iconStyle" />
                  </a>
                </span>
              </div>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default React.memo(NavBar);
