import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Set your base URL
const BASE_URL = "";

// Configure the base query to include the token in the headers
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.user?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Create the apiSlice with the baseQuery and endpoints
export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["Packages"],
  endpoints: (builder) => ({}),
});

export default apiSlice;
