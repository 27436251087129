import axios from "axios";

const API_URL = "/api/tickets/";

//initiateTicket

const initiateTicket = async (ticketData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(API_URL + "init", ticketData, config);

    return response.data;
  } catch (error) {
    throw error.response.data.message || error.message || error.toString();
  }
};

const ticketServices = {
  initiateTicket,
};

export default ticketServices;
