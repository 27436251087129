import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import eventService from "./eventService";

const initialState = {
  events: [],
  event: {},
  error: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAllEvents = createAsyncThunk(
  "events/getAllEvents",
  async () => {
    try {
      return await eventService.getAllEvents();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

// Async thunk for getting a single event by ID
export const getEventById = createAsyncThunk(
  "events/getEventById",
  async (eventId) => {
    try {
      return await eventService.getEventById(eventId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      throw new Error(message);
    }
  }
);

// Async thunk for creating a new event
export const createNewEvent = createAsyncThunk(
  "events/createNewEvent",
  async (eventData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await eventService.createEvent(eventData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      throw new Error(message);
    }
  }
);

// Async thunk for updating an existing event
export const updateExistingEvent = createAsyncThunk(
  "events/updateExistingEvent",
  async ({ eventId, eventData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await eventService.updateEvent(eventId, eventData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      throw new Error(message);
    }
  }
);

// Async thunk for deleting an event
export const deleteEventById = createAsyncThunk(
  "events/deleteEventById",
  async (eventId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await eventService.deleteEvent(eventId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      throw new Error(message);
    }
  }
);

// Create Event flyer
export const createEventFlyer = createAsyncThunk(
  "events/createEventFlyer",
  async (eventData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await eventService.createEventFlyer(eventData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      throw new Error(message);
    }
  }
);

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    clearState: (state) => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.events = action.payload;
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getEventById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEventById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.event = action.payload;
      })
      .addCase(getEventById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createNewEvent.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.event = action.payload;
      })
      .addCase(createNewEvent.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateExistingEvent.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.event = action.payload;
      })
      .addCase(updateExistingEvent.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteEventById.fulfilled, (state, action) => {
        state.isSuccess = true;
        // Handle state update after successful deletion, if needed
      })
      .addCase(deleteEventById.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createEventFlyer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEventFlyer.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        // Handle state update after successful flyer creation, if needed
      })
      .addCase(createEventFlyer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.message = action.payload;
      });
  },
});

export const { clearState } = eventSlice.actions;

export default eventSlice.reducer;
