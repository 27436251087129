import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSilce";
import eventReducer from "../features/events/eventSlice";
import formReducer from "../features/actions/formActions/formSlice";
import ticketReducer from "../features/actions/ticket/ticketSlice";
import { apiSlice } from "../features/actions/packagesActions/apiSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    events: eventReducer,
    form: formReducer,
    ticket: ticketReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
